<template id="rezervari">
  <div class="content">
      <h1 class="no_page">ACEASTA PROGRAMARE NU EXISTA</h1>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "No_page",
  components: {},
  data() {
    return {

      }
  },

  methods: {



  },
  mounted: async function () {
  },
};
</script>

<style lang="less" scoped>
    .content {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .no_page {
        font-size: 70px;
        font-weight: 600;
        user-select: none;
        opacity: 0.5;
        text-align: center;
    }

    @media only screen and (max-width: 768px) {
        .no_page {
            font-size: 40px;
        }
    }
</style>